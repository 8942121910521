export const MobileUserColums = [
  // {
  //   title: 'srno',
  //   dataIndex: 'srno',
  //   key: 'srno'
  // },
  {
    title: 'User Id',
    dataIndex: 'userId',
    key: 'userId'
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
    width: '10%'
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    notAllowToSearch: true
  },
  {
    title: 'Last Active At',
    dataIndex: 'lastActiveAt',
    key: 'lastActiveAt',
    notAllowToSearch: true
  },
  {
    title: 'Platforms',
    dataIndex: 'platform',
    key: 'platform',
    notAllowToSearch: true
  },  
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    notAllowToSearch: true
  },
  {
    title: 'Community',
    dataIndex: 'community',
    key: 'community'
  },
  {
    title: 'Team Name',
    dataIndex: 'teamName',
    key: 'teamName'
  },
  {
    title: 'Push Notifications',
    dataIndex: 'pushNotifications',
    key: 'pushNotifications',
    notAllowToSearch: true
  },
  {
    title: 'Newsletter',
    dataIndex: 'newsletter',
    key: 'newsletter',
    notAllowToSearch: true
  },
  {
    title: 'Timezone',
    dataIndex: 'timezone',
    key: 'timezone',
    notAllowToSearch: true
  },
  // {
  //   title: 'Total Trees',
  //   dataIndex: 'totalTrees',
  //   key: 'totalTrees'
  // },
  // {
  //   title: 'Trashed Removed',
  //   dataIndex: 'trashedRemoved',
  //   key: 'trashedRemoved'
  // },
  // {
  //   title: 'Meals',
  //   dataIndex: 'meals',
  //   key: 'meals'
  // },
  // {
  //   title: 'Voucher',
  //   dataIndex: 'voucher',
  //   key: 'voucher'
  // },
  {
    title: 'Verified',
    dataIndex: 'verified',
    key: 'verified',
    notAllowToSearch: true
  },
  // {
  //   title: 'Phone',
  //   dataIndex: 'phone',
  //   key: 'phone',
  //   ...getColumnSearchProps('Phone')
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  //   key: 'status'
  // },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '120px',
    notAllowToSearch: true
  }
]

export const AdminUsersTableColums = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
    width: '10%'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role'
  },
  {
    title: 'Platforms',
    dataIndex: 'platform',
    key: 'platform',
    notAllowToSearch: true
  },  
  {
    title: 'Community',
    dataIndex: 'community',
    key: 'community'
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    notAllowToSearch: true
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '120px',
    notAllowToSearch: true
  }

]

export const userDetailsMyChallenges = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Community',
    dataIndex: 'community',
    key: 'community'
  },
  {
    title: 'Time Period',
    dataIndex: 'timePeriod',
    key: 'timePeriod'
  },
  {
    title: 'Total Activities',
    dataIndex: 'totalActivities',
    key: 'totalActivities'
  },
  {
    title: 'Target Point',
    dataIndex: 'point',
    key: 'point'
  },
  {
    title: 'Reward',
    dataIndex: 'reward',
    key: 'reward'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Share Link',
    dataIndex: 'shareUrl',
    key: 'shareUrl'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '120px'
  }
]

export const userDetailsMyActivity = [
  {
    title: 'No.',
    dataIndex: 'srno',
    key: 'srno'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Community',
    dataIndex: 'community',
    key: 'community'
  },
  {
    title: 'Goal/Habit',
    dataIndex: 'goal',
    key: 'goal'
  },
  {
    title: 'Validation Method',
    dataIndex: 'validation',
    key: 'validation'
  },
  {
    title: 'Point Earned',
    dataIndex: 'point',
    key: 'point'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Share Link',
    dataIndex: 'link',
    key: 'link'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    width: '120px'
  }
]

/**
* 
*/
export const DeletedUserColums = [
  {
    title: 'User Id',
    dataIndex: 'userId',
    key: 'userId'
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
    width: '10%'
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    notAllowToSearch: true
  },
  {
    title: 'Last Active At',
    dataIndex: 'lastActiveAt',
    key: 'lastActiveAt',
    notAllowToSearch: true
  },
  {
    title: 'Deleted At',
    dataIndex: 'deletedAt',
    key: 'deletedAt',
    notAllowToSearch: true
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    notAllowToSearch: true
  }
]

export const UserLastHeroProgressColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Green',
    dataIndex: 'green',
    key: 'green',
    notAllowToSearch: true
  },
  {
    title: 'Blue',
    dataIndex: 'blue',
    key: 'blue',
    notAllowToSearch: true
  },
  {
    title: 'Red',
    dataIndex: 'red',
    key: 'red',
    notAllowToSearch: true
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    notAllowToSearch: true
  }
]


export const UserLastMyChallengesColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Name',
    dataIndex: 'challengeName',
    key: 'challengeName',
    notAllowToSearch: true
  },
  {
    title: 'Type',
    dataIndex: 'challengeType',
    key: 'challengeType',
    notAllowToSearch: true
  },
  {
    title: 'Window Mode',
    dataIndex: 'challengeCompletionMode',
    key: 'challengeCompletionMode',
    notAllowToSearch: true
  },
  {
    title: 'Duration',
    dataIndex: 'challengeDuration',
    key: 'challengeDuration',
    notAllowToSearch: true
  },
  {
    title: 'Progress (pts/target)',
    dataIndex: 'challengeProgress',
    key: 'challengeProgress',
    notAllowToSearch: true
  },
  {
    title: 'Status',
    dataIndex: 'challengeStatus',
    key: 'challengeStatus',
    notAllowToSearch: true
  },
  {
    title: 'Accepted At',
    dataIndex: 'acceptedAt',
    key: 'acceptedAt',
    notAllowToSearch: true
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    notAllowToSearch: true
  }
]


export const UserLastMyQuestsColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Name',
    dataIndex: 'questName',
    key: 'questName',
    notAllowToSearch: true
  },
  {
    title: 'Type',
    dataIndex: 'questType',
    key: 'questType',
    notAllowToSearch: true
  },
  {
    title: 'Window Mode',
    dataIndex: 'questCompletionMode',
    key: 'questCompletionMode',
    notAllowToSearch: true
  },
  {
    title: 'Duration',
    dataIndex: 'questDuration',
    key: 'questDuration',
    notAllowToSearch: true
  },
  {
    title: 'Progress (pts/target)',
    dataIndex: 'questProgress',
    key: 'questProgress',
    notAllowToSearch: true
  },
  {
    title: 'Status',
    dataIndex: 'questStatus',
    key: 'questStatus',
    notAllowToSearch: true
  },
  {
    title: 'Accepted At',
    dataIndex: 'acceptedAt',
    key: 'acceptedAt',
    notAllowToSearch: true
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    notAllowToSearch: true
  }
]