import { FETCH_ALL_ADMIN_USERS, FETCH_ALL_AVAILABLE_COMMUNITY_USERS } from './types'

const initialState = {
    adminUsers: [],
    availCommunityLeaders: []
}

const adminUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ADMIN_USERS:
            return { ...state, adminUsers: action.payload }
        case FETCH_ALL_AVAILABLE_COMMUNITY_USERS:
            return { ...state, availCommunityLeaders: action.payload }
        default:
            return state
    }
}

export default adminUsersReducer