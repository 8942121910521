//TODO: move roles to API
export const SUSGAIN_ROLES = {
  ADMIN: {
    role: 'admin',
    label: 'Admin',
    theme: ''
  }, 
  COMMUNITY_LEADER: {
    role: 'community_leader',
    label: 'Community Leader',
    theme: ''
  },
  ILIGHT: {
    role: 'i_light',
    label: 'Ilight Admin',
    theme: ''
  },
  CONTENT_EDITOR: {
    role: 'content_editor',
    label: 'Content Editor',
    theme: ''
  }
}