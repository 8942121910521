import React from 'react'
import {
  DashboardTwoTone,
  ContactsTwoTone,
  ShopTwoTone
} from '@ant-design/icons'
import { SUSGAIN_ROLES } from '../const/roles'
// import { Image } from 'antd'

export default [

  // {
  //   title: 'Dashboard',
  //   route: '/dashboard',
  //   module: 'DASHBOARD',
  //   role: [SUSGAIN_ROLES.ADMIN.role]
  // },
  // {
  //   title: 'My Corporate',
  //   route: '/corporate-dashboard',
  //   module: 'CORPORATE-DASHBOARD',
  //   role: [SUSGAIN_ROLES.COMMUNITY_LEADER.role]
  // },
  {
    title: 'Users',
    route: '/users',
    module: 'USERS',
    role: [SUSGAIN_ROLES.ADMIN.role],
    children: [
      {
        title: 'Mobile Users',
        route: '/users',
        module: 'USERS_MOBILE'
      },
      {
        title: 'Admin Users',
        route: '/users/admin',
        module: 'USERS_ADMIN'
      },
      {
        title: 'Account Deleted Users',
        route: '/users/deleted-users',
        module: 'USERS_ADMIN'
      }
    ]
  },
  {
    title: 'Transactions',
    route: '/transactions',
    module: 'TRANSACTIONS',
    role: [SUSGAIN_ROLES.ADMIN.role],
    children: [
      {
        title: 'Activity Transactions',
        route: '/transactions',
        module: 'ACTIVITY_ACTIONS_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Pending Activities Transactions',
        route: '/pending-transactions',
        module: 'ACTIVITY_ACTIONS_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Share with Community Transactions',
        route: '/share-with-community-transactions',
        module: 'SHARE_WITH_COMMUNITY_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Pending Share with Community Transactions',
        route: '/pending-share-with-community-transactions',
        module: 'SHARE_WITH_COMMUNITY_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Quest Transactions',
        route: '/quest-transactions',
        module: 'QUEST_ACTIONS_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Pending Quest Transactions',
        route: '/quest-pending-transactions',
        module: 'QUEST_ACTIONS_TRANSACTION'
        // role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },      
      {
        title: 'Redeem Rewards',
        route: '/transactions/redeem-rewards',
        module: 'TRANSACTION_POINTS-REWARDS'
      },
      {
        title: 'Challenge Rewards',
        route: '/transactions/challenges-rewards',
        module: 'TRANSACTION_CHALLENGE_REWARDS'
      },

      {
        title: 'ZOHO Transactions',
        route: '/transactions/zoho',
        module: 'ZOHO_TRANSACTIONS'
      },
      {
        title: 'Vouchers Reward',
        route: '/transactions/vouchers',
        module: 'VOUCHERS_REWARD'
      },
      {
        title: 'Steps Tracking Logs',
        route: '/transactions/steps-tracking-logs',
        module: 'STEPS_TRACKING_LOGS'
      }
    ]
  },
  // {
  //   title: 'Transactions',
  //   route: '/transactions',
  //   module: 'TRANSACTIONS',
  //   role: [SUSGAIN_ROLES.COMMUNITY_LEADER.role],
  //   children: [
  //     {
  //       title: 'Rewards Transaction',
  //       route: '/transactions/community-rewards',
  //       module: 'TRANSACTION_COMMUNITY_REWARDS',
  //       role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
  //     },
  //     {
  //       title: 'Activity Actions',
  //       route: '/transactions',
  //       module: 'ACTIVITY_ACTIONS_TRANSACTION'
  //     }
  //   ]
  // },
  {
    title: 'Manage Community',
    module: 'MANAGE_COMMUNITY',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role],
    children: [
      {
        title: 'Communities',
        route: '/communities',
        module: 'COMMUNITY',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Teams',
        route: '/teams',
        module: 'TEAMS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      }
    ]
  },
  {
    title: 'Community Reports',
    module: 'COMMUNITY_REPORTS',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role],
    children: [
      {
        title: 'Activities Transactions',
        route: '/transactions',
        module: 'ACTIVITY_ACTIONS_TRANSACTION',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Pending Activities Transactions',
        route: '/pending-transactions',
        module: 'ACTIVITY_ACTIONS_TRANSACTION',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Share with Community Transactions',
        route: '/share-with-community-transactions',
        module: 'SHARE_WITH_COMMUNITY_TRANSACTION',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Pending Share with Community Transactions',
        route: '/pending-share-with-community-transactions',
        module: 'SHARE_WITH_COMMUNITY_TRANSACTION',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Challenges Action',
        route: '/leaderboard/challenges-transactions',
        module: 'CHALLENGE_ACTIONS_TRANSACTION',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Community Rewards Transaction',
        route: '/transactions/community-rewards',
        module: 'TRANSACTION_COMMUNITY_REWARDS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Community Scoring Transaction',
        route: '/transactions/community-scoring',
        module: 'TRANSACTION_COMMUNITY_SCORING',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Community Goals',
        route: '/community-goals',
        module: 'COMMUNITY_GOALS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Leaderboard - By Team',
        route: '/leaderboard/by-teams',
        module: 'LEADERBOARD_BY_TEAMS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Leaderboard - By Member',
        route: '/leaderboard/by-members',
        module: 'LEADERBOARD_BY_MEMBERS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Engagement - Number of Teams & Individuals',
        route: '/leaderboard/engagement-by-community',
        module: 'ENGAGEMENT_BY_COMMUNITY',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Engagement - By Joining Rate',
        route: '/leaderboard/engagement-by-team-joining-rate',
        module: 'ENGAGEMENT_BY_TEAM_JOINING_RATE',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Engagement - By Participation KPIs',
        route: '/leaderboard/engagement-by-participation-kpis',
        module: 'ENGAGEMENT_BY_PARTICIPATION_KPIS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Engagement - By Activity KPIs',
        route: '/leaderboard/engagement-by-activity-kpis',
        module: 'ENGAGEMENT-BY-ACTIVITY-KPIS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Impact - By Activities',
        route: '/leaderboard/impact-by-activities',
        module: 'IMPACT-BY-ACTIVITIES',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Impact - By Goals',
        route: '/leaderboard/impact-by-goals',
        module: 'IMPACT-BY-GOALS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Impact - By Habits',
        route: '/leaderboard/impact-by-habits',
        module: 'IMPACT-BY-HABITS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      },
      {
        title: 'Impact - By Rewards',
        route: '/leaderboard/impact-by-rewards',
        module: 'IMPACT-BY-REWARDS',
        role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role]
      }

    ]
  },
  {
    title: 'ILight',
    route: '/iLight',
    module: 'I_LIGHT',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },
  {
    title: 'Domain Whitelisted',
    route: '/domains',
    module: 'DOMAIN_WHITELISTED',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },
  {
    title: 'Device Blacklisted',
    route: '/devices',
    module: 'DEVICE_BLACKLISTED',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },
  {
    title: 'Broadcasting Tool',
    route: '/broadcasting',
    module: 'BROADCASTING_TOOL',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },

  {
    title: 'Messaging',
    module: 'MESSAGING',
    role: [SUSGAIN_ROLES.ADMIN.role],
    children: [
      {
        title: 'New Push Message',
        route: '/messaging/add',
        module: 'MESSAGING',
        role: [SUSGAIN_ROLES.ADMIN.role]
      },
      {
        title: 'Sent Messages',
        route: '/messaging',
        module: 'MESSAGING',
        role: [SUSGAIN_ROLES.ADMIN.role]
      },
      {
        title: 'Queue Logs',
        route: '/pns-queue-logs',
        module: 'MESSAGING',
        role: [SUSGAIN_ROLES.ADMIN.role]
      }
    ]
  },
  {
    title: 'Banners',
    route: '/banners',
    module: 'BANNERS',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]
  },
  {
    title: 'Activities',
    route: '/activities',
    module: 'ACTIVITIES',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]
  },
  {
    title: 'Challenges',
    route: '/challenges',
    module: 'CHALLENGES',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]
  },
  {
    title: 'Quests',
    route: '/quests',
    module: 'QUESTS',
    role: [SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]
  },
  {
    title: 'Goals & Habits',
    route: '/goals',
    module: 'GOALS',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },
  {
    title: 'Locations',
    route: '/locations',
    module: 'LOCATIONS',
    role: [SUSGAIN_ROLES.ADMIN.role]
  },
  // {
  //   title: 'Mia Animations Testing',
  //   route: '/miaAnimations',
  //   module: 'MiaAnimations',
  //   role: [SUSGAIN_ROLES.ADMIN.role]
  // },
  {
    title: 'App Version',
    route: '/appVersion',
    module: 'appVersion',
    role: [SUSGAIN_ROLES.ADMIN.role]
  }
]
