import React, { useState, useEffect } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { Card, Form, Input, Button, Checkbox } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { toggleNetworkLoading } from '../../../store/common/actions'
import { doLogin } from '../../../store/auth/actions'
import { canIAccessThisWithMyRoles } from "../../../const/utils"
import { SUSGAIN_ROLES } from "../../../const/roles"
import ForgotPasswordModal from "../ForgotPassword"
import { UnlockOutlined } from '@ant-design/icons'


const Login = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const networkLoading = useSelector((state) => {
    return state.common.networkLoading
  })

  useEffect(() => {
    dispatch(toggleNetworkLoading(false))
  }, [])

  const goToDashboard = () => {
    if (canIAccessThisWithMyRoles([SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role])) {
      navigate('/dashboard')
    } else if (canIAccessThisWithMyRoles([SUSGAIN_ROLES.COMMUNITY_LEADER.role])) {
      navigate('/corporate-dashboard')
    } else if (canIAccessThisWithMyRoles([SUSGAIN_ROLES.ILIGHT.role])) {
      navigate('/iLight')
    } else {
      navigate('/not-authorized')
    }
  }

  const submitFormLogin = (values) => {
    dispatch(doLogin(values, goToDashboard))
  }

  const successUpdateCallback = () => {
    console.log('refresh')
  }

  const _renderFooter = () => {
    return (
      <p className='text-gray-400 mb-0 text-center'>{`Ⓒ susGain 2024`}</p>
    )
  }

  return (
    <div className="md:pt-40 login-bg" style={{ backgroundImage: 'url(/assets/illustrations/login/background-particle2.svg)' }}>
      <div>
        <Card className="mx-auto" style={{ boxShadow: '0 4px 24px 0 rgb(34 41 47 / 36%)', maxWidth: 350 }}>
          <img className='mx-auto' src={require('../../../assets/images/logo-sm.png')} alt='Susgain' />

          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={submitFormLogin}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please provide valid email.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please provide valid password.' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item className="text-center">
              <Button type="dashed" htmlType="submit" loading={networkLoading}>
                Login
              </Button>
            </Form.Item>

            <Form.Item className="text-center">
              <Button
                type='link'
                className='items-center'
                style={{ color: '#207868' }}
                icon={<UnlockOutlined />}
                onClick={() => setVisible(true)}
              >
                Forgot Password
              </Button>
            </Form.Item>
          </Form>

          <ForgotPasswordModal
            visible={visible}
            setVisible={setVisible}
            refresh={successUpdateCallback}
          />
          <div>
            {_renderFooter()}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Login
