export const ActivityActionsTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      notAllowToSearch: true
    },
    {
      title: 'Submitted Date',
      dataIndex: 'submittedData',
      key: 'submittedData',
      notAllowToSearch: true
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      notAllowToSearch: true
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      notAllowToSearch: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      notAllowToSearch: true
    },
    {
      title: 'Activity Name',
      dataIndex: 'activity',
      key: 'activity',
      notAllowToSearch: true
    },
    // {
    //   title: 'Goal',
    //   dataIndex: 'goal',
    //   key: 'goal'
    // },
    {
      title: 'Transaction Method',
      dataIndex: 'transactionMethod',
      key: 'transactionMethod'
    },
    // {
    //   title: 'Habits',
    //   dataIndex: 'habits',
    //   key: 'habits',
    //   notAllowToSearch: true
    // },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      notAllowToSearch: true
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      notAllowToSearch: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      notAllowToSearch: true
    //   sorter: (a, b) => a.status.length - b.status.length,
    //   sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '120px',
      notAllowToSearch: true
    }
  ]

  export const ChallengesRewardTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      notAllowToSearch: true
    },
    {
      title: 'Submitted Date',
      dataIndex: 'submittedData',
      key: 'submittedData',
      notAllowToSearch: true
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      notAllowToSearch: true
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
      key: 'fullname',
      notAllowToSearch: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      notAllowToSearch: true
    },
    {
      title: 'Challenge Name',
      dataIndex: 'challenge',
      key: 'challenge',
      notAllowToSearch: true
    },     
    {
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      notAllowToSearch: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      notAllowToSearch: true
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      notAllowToSearch: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      notAllowToSearch: true
    }
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   key: 'action',
    //   fixed: 'right',
    //   width: '120px'
    // }
  ]

  export const RedeemRewardsTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      notAllowToSearch: true
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      notAllowToSearch: true
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      notAllowToSearch: true
    },    
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      notAllowToSearch: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      notAllowToSearch: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      notAllowToSearch: true
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
      key: 'reward',
      notAllowToSearch: true
    },
    {
      title: 'Points Redeem',
      dataIndex: 'points',
      key: 'points',
      notAllowToSearch: true
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      notAllowToSearch: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      notAllowToSearch: true
    },
    {
      notAllowToSearch: true,
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '120px'
    }
  ]

  export const CommunityImpactRewardTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    // {
    //   title: 'Transaction ID',
    //   dataIndex: 'transactionId',
    //   key: 'transactionId',
    //   notAllowToSearch: true
    // },
    {
      title: 'Submitted Date',
      dataIndex: 'submittedData',
      key: 'submittedData',
      notAllowToSearch: true
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      notAllowToSearch: true
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
      key: 'fullname',
      notAllowToSearch: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      notAllowToSearch: true
    },
    {
      title: 'Community Name',
      dataIndex: 'communityName',
      key: 'communityName',
      notAllowToSearch: true
    },
    {
      title: 'Team Name',
      dataIndex: 'teamName',
      key: 'teamName',
      notAllowToSearch: true
    },
    {
      title: 'Impact',
      dataIndex: 'impactType',
      key: 'impactType',
      notAllowToSearch: true
    },
    {
      title: 'Reward Source',
      dataIndex: 'rewardSource',
      key: 'rewardSource',
      notAllowToSearch: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      notAllowToSearch: true
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      notAllowToSearch: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      notAllowToSearch: true
    }
  ]

  export const CommunityScoringTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    // {
    //   title: 'Transaction ID',
    //   dataIndex: 'transactionId',
    //   key: 'transactionId',
    //   notAllowToSearch: true
    // },
    {
      title: 'Submitted Date',
      dataIndex: 'submittedData',
      key: 'submittedData',
      notAllowToSearch: true
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      notAllowToSearch: true
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
      key: 'fullname',
      notAllowToSearch: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      notAllowToSearch: true
    },
    {
      title: 'Community Name',
      dataIndex: 'communityName',
      key: 'communityName',
      notAllowToSearch: true
    },
    {
      title: 'Team Name',
      dataIndex: 'teamName',
      key: 'teamName',
      notAllowToSearch: true
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      notAllowToSearch: true
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      notAllowToSearch: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      notAllowToSearch: true
    },
    {
      notAllowToSearch: true,
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '120px'
    }
  ]