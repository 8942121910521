import ServerApi from "../../utility/ServerApi"
import { toggleNetworkLoading } from "../common/actions"
import { FETCH_TEAMS_LEADERBOARD, FETCH_MEMBERS_LEADERBOARD, FETCH_ENGAGEMENT_BY_COMMUNITY, FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE, FETCH_IMPACT_BY_ACTIVITIES, FETCH_IMPACT_BY_GOALS, FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS, FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS, FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST, FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS, FETCH_COMMUNITY_DETAIL_CHALLENGES_TRANSACTIONS } from './types'
import ServerApiCSV from "../../utility/ServerApiCSV"
import { message } from 'antd'
import moment from 'moment'

export const fetchCommunityReportsCommunitiesList = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/community-report/communities-list`).then((res) => {
        dispatch({
            type: FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchLeaderBoardTeams] ----err----- ', err)
    })
}

//============================Team LeaderBoard
export const fetchLeaderBoardTeams = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/community-report/team/teams-leaderboard-report?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_TEAMS_LEADERBOARD,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchLeaderBoardTeams] ----err----- ', err)
    })
}

export const exportLeaderBoardTeams = (filters, _selected_rows = []) => dispatch => {
    ServerApiCSV().post(`/community-report/team/teams-leaderboard-report/export-csv-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `teams_leader_board_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            console.error(e)
        })
}
//==============================Member LeaderBoard
export const fetchLeaderBoardMembers = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/members-leaderboard-report?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_MEMBERS_LEADERBOARD,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchLeaderBoardMembers] ----err----- ', err)
    })
}

export const exportLeaderBoardMember = (filters, _selected_rows = []) => dispatch => {
    ServerApiCSV().post(`/community-report/team/members-leaderboard-report/export-csv-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `members_leader_board_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            console.error(e)
        })
}

//====================Engagement By Community
export const fetchEngagementByCommunity = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/engagement-by-community?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_ENGAGEMENT_BY_COMMUNITY,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchEngagementByCommunity] ----err----- ', err)
        dispatch({
            type: FETCH_ENGAGEMENT_BY_COMMUNITY,
            payload: null
        })
    })
}

/**
 * #44821 - Engagement - By Community Overview
 * @param {*} filters 
 * @param {*} _selected_rows 
 * @returns 
 */
export const fetchEngagementByCommunityExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/engagement-by-community-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `engagement-number-of-teams-individuals_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

//====================Engagement By Team Joining Rate
export const fetchEngagementByTeamJoiningRate = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/engagement-by-team-joining-rate?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchEngagementByTeamJoiningRate] ----err----- ', err)
        dispatch({
            type: FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE,
            payload: null
        })
    })
}

export const fetchEngagementByTeamJoiningRateExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/engagement-by-team-joining-rate-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `engagement-by-joining-rate_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

//====================Engagement By Participation KPIs
export const fetchEngagementByParticipationKPIs = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/engagement-by-participation?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchEngagementByParticipationKPIs] ----err----- ', err)
        dispatch({
            type: FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS,
            payload: null
        })
    })
}

export const engagementByParticipationKPIsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/engagement-by-participation-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            dispatch(toggleNetworkLoading(false))
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `engegament-by-participation_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44826 - Impact 1 - By Activities
 * @param {*} communityId 
 * @param {*} teamId 
 * @returns 
 */
export const fetchImpactByActivities = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/impact-1-by-activities/?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_IMPACT_BY_ACTIVITIES,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchImpactByActivities] ----err----- ', err)
        dispatch({
            type: FETCH_IMPACT_BY_ACTIVITIES,
            payload: null
        })
    })
}
export const impactByActivitiesExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/impact-1-by-activities-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `impact-by-activities_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44829 - Impact 3 - By Goals
 * @param {*} filters 
 * @returns 
 */
export const fetchImpactByGoals = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/impact-1-by-goals/?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchImpactByGoals] ----err----- ', err)
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: null
        })
    })
}
export const impactByGoalsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/impact-1-by-goals-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `impact-by-goals_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44828 - Impact - By Habits
 * @param {*} filters 
 * @returns 
 */
export const fetchImpactByHabits = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/impact-1-by-habits/?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchImpactByHabits] ----err----- ', err)
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: null
        })
    })
}
export const impactByHabitsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/impact-1-by-habits-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `impact-by-habits_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44830 - Impact 4 - By Rewards
 * @param {*} filters 
 * @returns 
 */
export const fetchImpactByRewards = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/impact-1-by-rewards/?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchImpactByHabits] ----err----- ', err)
        dispatch({
            type: FETCH_IMPACT_BY_GOALS,
            payload: null
        })
    })
}

/**
 * #44830 - Impact 4 - By Rewards
 * @param {*} filters 
 * @param {*} _selected_rows 
 * @returns 
 */
export const impactByRewardsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/impact-1-by-rewards-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `impact-by-rewards_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44825 - Engagement 5 - By Activity KPIs
 * @param {*} filters 
 * @returns 
 */
export const fetchEngagementByActivityKpis = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/team/engagement-by-activity-kpis/?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchEngagementByActivityKpis] ----err----- ', err)
        dispatch({
            type: FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS,
            payload: null
        })
    })
}

/**
 * #44825 - Engagement 5 - By Activity KPIs
 * @param {*} filters 
 * @param {*} _selected_rows 
 * @returns 
 */
export const fetchEngagementByActivityKpisExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/team/engagement-by-activity-kpis-export-csv-all?${filters?.status ? `&status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `engagement-by-activity-kpis_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const fetchCommunityChallengesTransactions = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`community-report/challenges-transactions?${filters?.status ? `status=${filters?.status}` : ''}&current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchCommunityChallengesTransactions] ----err----- ', err)
    })
}

export const fetchCommunityChallengesTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`community-report/challenges-transactions-export-csv-all?${filters?.status ? `status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `challenges-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #45331 - New Community Points report - detail of every transactions
 * @param {*} filters 
 * @returns 
 */
export const fetchCommunityDetailTransactionsExportCsvAll = (filters) => dispatch => {
    ServerApiCSV().post(`community-report/team/members-leaderboard-report/export-csv-all?${filters?.status ? `status=${filters?.status}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.validationMethod ? `&validationMethod=${filters?.validationMethod}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: [] })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `community-detail-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}