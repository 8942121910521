import { Collapse, Button, DatePicker, Radio, Select, Form, Input, Upload, Typography, message, InputNumber, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { createQuest } from '../../../store/quests/actions'
import { fetchAllActivitiesForCreateEditQuest } from '../../../store/activity/actions'
import { fetchAllGoals } from '../../../store/goal/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { fetchAllRewadsForQuest } from '../../../store/rewards/actions'
import { fetchAllSDGS } from '../../../store/sdgs/actions'
import ServerApi from '../../../utility/ServerApi'
// import { SDGS } from '../../../const/sdgs.const'
import { fetchPublishedLocations } from '../../../store/locations/actions'
import { isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA, transformValidationMethod } from '../../../const/utils'
import { QUEST_WINDOW_MODE, transformVoucherCodeInput } from './Quests.const'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import PreviewImage from '../../../components/image/PreviewImage'
import draftToHtml from 'draftjs-to-html'
// import LazyImage from '../../../components/image/LazyImage'
import CustomColorPicker from './CustomColorPicker'

const { Panel } = Collapse
const { RangePicker } = DatePicker

export const AddQuest = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activityData = useSelector(state => state.activity.activitiesForEditCreateQuest)
    // const habitData = useSelector(state => state.goals.goals)
    const communityData = useSelector(state => state.community.communities)
    const rewards = useSelector(state => state.rewards.rewards)
    const sdgs = useSelector(state => state.sdgs.sdgs)
    // const locations = useSelector(state => state.locations.locations)
    const [questCurrentThemeColor, setQuestCurrentThemeColor] = useState('#119D49')
    const [rewardOption, setRewardOption] = useState("")
    // const [fileList, setFileList] = useState([])
    const [orgLogoFileList, setOrgLogoFileList] = useState([])
    const [orgBackgroundFileList, setOrgBackgroundFileList] = useState([])
    // const [thumbnailFileList, setThumbnailFileList] = useState([])
    // const [voucherFileList, setVoucherFileList] = useState([])

    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)
    const [completionWindowMode, setCompletionWindowMode] = useState('')
    // const [emailContent, setEmailContent] = useState('')
    // const [emailLogo, setEmailLogo] = useState([])
    const [emailContentEditorState, setEmailContentEditorState] = useState(EditorState.createEmpty())
    const [numberVoucherEntered, setNumberVoucherEntered] = useState(0)
    // const editorEmailContentText = useSelector(state => '')

    const themeBackgroundPreviewRef = useRef(null)
    const themeContainerPreviewRef = useRef(null)

    const themePrimaryPreviewCSS = {
        background: questCurrentThemeColor,
        padding: '8px 0'
    }

    const themeBackgroundPreviewCSS = {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, ${questCurrentThemeColor}, ${questCurrentThemeColor})
        `,
        padding: '8px 0'
    }

    const themeContainerPreviewCSS = {
        background: `linear-gradient(0deg, rgba(13, 12, 12, 0.9), rgba(13, 12, 12, 0.9)), linear-gradient(0deg, ${questCurrentThemeColor}, ${questCurrentThemeColor})`,
        padding: '8px 0'
    }

    useEffect(() => {
        // dispatch(fetchAllActivitiesForCreateEditQuest({}))
        dispatch(fetchAllGoals())
        dispatch(fetchAllCommunity())
        dispatch(fetchAllRewadsForQuest())
        dispatch(fetchAllSDGS())
        dispatch(fetchPublishedLocations())
    }, [])

    const successCallback = () => {
        navigate('/quests')
    }

    useEffect(() => {
        //teams options based on selected community
        // dispatch(fetchAllActivitiesForCreateEditQuest({ communityId: selectedCommunity, city: selectedCity }))
        // setSelectedCity(selectedCommunity.cities)
        // force reset team selected
        // form.setFieldsValue('activityIds', undefined)
    }, [selectedCommunity])

    useEffect(() => {
        //teams options based on selected community
        dispatch(fetchAllActivitiesForCreateEditQuest({ communityId: selectedCommunity, city: selectedCity }))
        // force reset team selected
        form.setFieldsValue({ activityIds: undefined })
    }, [selectedCity, selectedCommunity])

    // useEffect(() => {
    //     setTimeout(() => {
    //         const blocksFromHTML = convertFromHTML(editorEmailContentText)
    //         const state = ContentState.createFromBlockArray(
    //             blocksFromHTML.contentBlocks,
    //             blocksFromHTML.entityMap
    //         )
    //         setEmailContentEditorState(EditorState.createWithContent(state))
    //         setEmailContent(editorEmailContentText || '')
    //     }, 1000)
    // }, [editorEmailContentText])


    /**
     * 
     * @param {*} values 
     */
    const createNewQuest = (values) => {
        values.timePeriod = {
            from: values.timePeriod ? values.timePeriod[0] : '',
            to: values.timePeriod ? values.timePeriod[1] : ''
        }
        values.rewardDetails = {
            extraPoint: values.extraPoint,
            tree: values.plantTree,
            meal: values.mealForChildren,
            trash: values.removeTrash,
            voucher: {
                voucher_codes: transformVoucherCodeInput(values.voucher_codes),
                original_voucher_codes: transformVoucherCodeInput(values.voucher_codes),
                zepto_mail_template_id: values.zepto_mail_template_id
            }
        }
        // if (fileList[0]) values.image = fileList[0].image
        // if (thumbnailFileList[0]) values.thumbnail = thumbnailFileList[0].icon
        //get brand color
        const bgColor = window.getComputedStyle(ReactDOM.findDOMNode(themeBackgroundPreviewRef?.current))['background-image']
        const containerColor = window.getComputedStyle(ReactDOM.findDOMNode(themeContainerPreviewRef?.current))['background-image']
        const branding = {
            orgName: values.orgName,
            logo: '',
            theme: {
                primary_color: questCurrentThemeColor,
                background_color: bgColor,
                container_color: containerColor
            },
            backgroundImage: ''
        }
        if (orgLogoFileList[0]) branding.logo = orgLogoFileList[0].image
        if (orgBackgroundFileList[0]) branding.backgroundImage = orgBackgroundFileList[0].image
        values.brandInfo = branding
        dispatch(createQuest(values, successCallback))
    }

    const handleOrgLogo = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setOrgLogoFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setOrgLogoFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleOrgBackground = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setOrgBackgroundFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setOrgBackgroundFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const orgLogoProps = {
        customRequest: handleOrgLogo,
        listType: 'picture',
        onRemove: () => setOrgLogoFileList([]),
        orgLogoFileList
    }

    const orgBackgroundProps = {
        customRequest: handleOrgBackground,
        listType: 'picture',
        onRemove: () => setOrgBackgroundFileList([]),
        orgBackgroundFileList
    }
 
    /**
     * #43616 - QUEST - time period logic update
     * @param {*} e 
     */
    const handleWindowModeChange = (e) => {
        setCompletionWindowMode(e.target.value)
    }

    // const onEditorEmailStateChange = (editorState) => {
    //     setEmailContentEditorState(editorState)
    // }

    const handleOnChangeColorComplete = (color) => {
        console.log(`The selected color is ${color.toHexString()}`)
        setQuestCurrentThemeColor(color.toHexString())
    }

    const handleCountVoucher = (e) => {
        if (e?.target?.value !== '') {
            const splittedVoucher = e.target.value.split(',')
            //check for empty element
            const newArr = splittedVoucher.filter(element => {
                if (element === "") {
                    return false
                } else {
                    return true
                }
            })
            setNumberVoucherEntered(newArr.length)
        } else {
            setNumberVoucherEntered(0)
        }
    }

    return (
        <>
            <div className="shadow bg-white">
                <Form
                    name="NewQuests"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => createNewQuest(values)}
                    initialValues={{ publish: false, sharelink: "Link will be auto generated when form is submitted!" }}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['branding', '1', '2', '3', '4']}>
                        <Panel header={<p className='font-bold text-lg'>Branding Info</p>} key="branding" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Name of Organization"
                                name="orgName"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Name of Organization!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Logo"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_ORG_LOGO}
                            >
                                <div className='flex justify-end'>
                                    <Upload className='flex space-x-4' {...orgLogoProps} fileList={orgLogoFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Background Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_ORG_BG}
                            >
                                <div className='flex justify-end'>
                                    <Upload className='flex space-x-4' {...orgBackgroundProps} fileList={orgBackgroundFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="theme"
                                colon={false}
                                labelAlign="left"
                                label="Theme"
                                rules={[{ required: false, message: 'Please select theme' }]}
                            >
                                <Space direction='vertical'>
                                    <Space>
                                        {/* <ColorPicker
                                            defaultValue={questCurrentThemeColor} showText
                                            onChangeComplete={handleOnChangeColorComplete} /> */}
                                        <CustomColorPicker
                                            defaultValue={questCurrentThemeColor}
                                            showText
                                            disabledAlpha
                                            onChangeComplete={handleOnChangeColorComplete}></CustomColorPicker>
                                    </Space>
                                    <Space>
                                        {/* <label>Preview:</label> */}
                                        <Space direction='vertical'><label>Primary</label>
                                            <div id='themePrimaryPreviewCSS' className={`w-20 h-20`} style={themePrimaryPreviewCSS}></div></Space>
                                        <Space direction='vertical'><label>Background</label>
                                            <div ref={themeBackgroundPreviewRef} className="w-20 h-20" style={themeBackgroundPreviewCSS}></div></Space>
                                        <Space direction='vertical'><label>Container</label>
                                            <div ref={themeContainerPreviewRef} className="w-20 h-20" style={themeContainerPreviewCSS}></div></Space>
                                    </Space>
                                </Space>
                                {/* <Radio.Group className="text-left">
                                    {Object.values(QUEST_THEMES).map(ik => (
                                        <div><Radio key={ik.label}
                                            value={ik.value}>{ik.label}
                                            {ik.sample && <LazyImage
                                                fileName={ik.sample}
                                                className="w-14"
                                                alt={ik.value}></LazyImage>
                                            }
                                        </Radio></div>
                                    ))}
                                </Radio.Group> */}
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>General Info</p>} key="1" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Quest Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Quest name!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Share Link"
                                name="sharelink"
                                labelAlign="left"
                                colon={false}
                                // rules={[{ required: true, message: 'Please enter Activity name!' }]}
                                hasFeedback
                            >
                                <Input disabled />
                            </Form.Item>
                            {/* <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Quest Thumbnail"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_THUMB}
                            >
                                <div className='flex justify-end'>
                                    <Upload className='flex space-x-4' {...thumbnailProps} fileList={thumbnailFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Quest Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.QUEST_MAIN}
                            >
                                <div className='flex justify-end'>
                                    <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item> */}
                            <Form.Item
                                label="Community"
                                name="communityIds"
                                labelAlign="left"
                                colon={false}
                                extra="Leave blank if global"
                            // rules={[{ required: true, message: 'Please select community!' }]}
                            // hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select community"
                                    optionFilterProp='label'
                                    options={communityData?.result?.map(i => ({ label: i.name, value: i._id }))}
                                    onChange={(h) => { setSelectedCommunity(h) }}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {/* <Form.Item
                                label="Cities"
                                name="cities"
                                labelAlign="left"
                                colon={false}
                                // rules={[{ required: true, message: 'Please select cities!' }]}
                                hasFeedback
                                extra={'Leave blank if global'}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    placeholder="Select City"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={(h) => { setSelectedCity(h) }}
                                    suffixIcon={<CgArrowsV />}
                                    options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                                >
                                </Select>
                            </Form.Item> */}
                            <Form.Item
                                name="windowMode" colon={false} labelAlign="left" label="Completion window mode"
                                rules={[{ required: true, message: 'Please completion mode' }]}
                                onChange={handleWindowModeChange}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={QUEST_WINDOW_MODE.TIME_PERIOD.value}>{QUEST_WINDOW_MODE.TIME_PERIOD.label}</Radio>
                                    <Radio value={QUEST_WINDOW_MODE.COMPLETE_WITHIN.value}>{QUEST_WINDOW_MODE.COMPLETE_WITHIN.label}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {completionWindowMode == QUEST_WINDOW_MODE.TIME_PERIOD.value &&
                                <div className="grid grid-cols-7 gap-3">
                                    <Form.Item
                                        label="From / To Date"
                                        name="timePeriod"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter duration!' }]}
                                        labelCol={{
                                            span: 7
                                        }}
                                        className="col-span-4"
                                        extra='The quest will be available from date and to date only'
                                    >
                                        <RangePicker style={{ width: "100%" }} />
                                    </Form.Item>
                                </div>
                            }
                            {completionWindowMode == QUEST_WINDOW_MODE.COMPLETE_WITHIN.value &&
                                <>
                                    <Form.Item
                                        label="Complete Within"
                                        name="completeWithin"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter number of days!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only! Value from 1..180.' }
                                        ]}
                                        hasFeedback
                                        extra='Number of days user should complete this quest since accepted. Value from 1..180.'
                                    >
                                        <InputNumber placeholder='Days' min={1} max={180} />
                                    </Form.Item>
                                </>
                            }
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Activities</p>} key="2" className="bg-textbackgroundcolor">
                            {/* <Form.Item
                                label="Total number"
                                name="totalnumber"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Total number!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item> */}
                            <Form.Item
                                label="Activities"
                                name="activityIds"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select activities!' }]}
                                hasFeedback
                                extra={'Only activities has type as WEB available here'}
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Multiple Selection"
                                    optionFilterProp='label'
                                    options={activityData?.result?.map(i => ({ label: `${i.name} (${transformValidationMethod(i.validationMethod)}, ${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i._id }))}
                                >
                                </Select>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Target and Reward</p>} key="3" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Target Point"
                                name="points"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    { required: true, message: 'Please enter maximum points!' },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                hasFeedback
                                extra='Target points to complete this quest. Value from 1..999999'
                            >
                                <InputNumber placeholder='Points' min={1} max={999999} />
                            </Form.Item>
                            <Form.Item
                                label="Reward"
                                name="rewardId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select reward type!' }]}
                                hasFeedback
                                extra='Award item when user complete this quest.'
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Reward"
                                    optionFilterProp="label"
                                    onSelect={(e, s) => setRewardOption(s.rewardId)}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={rewards?.map((r, it) => ({ label: r?.name, value: r?._id, rewardId: r?.rewardId }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {rewardOption === 'tree' && (
                                <Form.Item
                                    label="Number of tree"
                                    name="plantTree"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter number of trees!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trees' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {rewardOption === 'meal' && (
                                <Form.Item
                                    label="Number of meal"
                                    name="mealForChildren"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter number of meals!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Meals' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {rewardOption === 'trash_remove' && (
                                <Form.Item
                                    label="Number of grams of trash"
                                    name="removeTrash"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter number of trash remove!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trash' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {rewardOption == 'xpoints' && (
                                <Form.Item
                                    label="Number of Points"
                                    name="extraPoint"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Points' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {rewardOption == 'voucher' && (
                                <>
                                    <Form.Item
                                        name='voucher_codes'
                                        label="Voucher Code"
                                        labelAlign="left"
                                        colon={false}
                                        extra={
                                            <>
                                                <p className='text-sm text-blue-500 pt-2 font-bold'>Total codes available {numberVoucherEntered}</p>
                                                <p className='text-sm py-2'>Please enter all code can be used seperated by comma</p>
                                            </>
                                        }
                                    >
                                        <Input.TextArea rows={4} onChange={(e) => handleCountVoucher(e)} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Template ID"
                                        name="zepto_mail_template_id"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter template id.' }]}
                                        hasFeedback
                                        extra={'Zoho ZepToMail Template ID. This needs to be customizable by project. We can custom by each template per Quest'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            )}
                            {/* {rewardOption == 'voucher' && (
                                <Form.Item
                                    label="Email Content"
                                    name="email_content"
                                    labelAlign="left"
                                    colon={false}
                                    hasFeedback
                                    extra={
                                        <>
                                            <p className='text-sm py-2'>Template variable available: {`{{voucher_code}}, {{quest_name}}, {{email_logo}}`}.</p>
                                        </>
                                    }
                                >
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }

                                        }}
                                        editorState={emailContentEditorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorEmailStateChange}
                                        onChange={() => {
                                            const tempData = (emailContentEditorState && draftToHtml(convertToRaw(emailContentEditorState.getCurrentContent())))
                                            setEmailContent(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>
                            )}
                            {rewardOption == 'voucher' && (
                                <Form.Item
                                    labelAlign="left"
                                    colon={false}
                                    label="Email Logo"
                                    extra={
                                        <>
                                            <p className='py-2'>{SUPPORTED_FILE_FORMAT_EXTRA.QUEST_THUMB}</p>
                                            <p>This logo if uploaded will be used for the template variable {`{{email_logo}}`} when the system sending email to user</p>
                                        </>
                                    }
                                >
                                    <div className='flex justify-between'>
                                        <div className='self-center'>
                                            <p className='flex items-center'>Currently : <PreviewImage src={emailLogo} width={120} height={69} /></p>
                                        </div>
                                        <Upload className='flex space-x-4' {...emailLogoProps} fileList={emailLogo}>
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            )} */}
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Details</p>} key="4" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Description"
                                name="description"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter description!' }]}
                                hasFeedback
                            >
                                <Input.TextArea showCount rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="Instruction"
                                name="instructions"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Instruction!' }]}
                                hasFeedback
                            >
                                <Input.TextArea showCount rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="SDGs"
                                name="sdgs"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select SDGs!' }]}
                                hasFeedback
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    placeholder="Multiple Selection"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                // options={sdgs?.map(i => ({ label: i.name, value: i._id }))}

                                >
                                    {sdgs.map(i => (
                                        <Select.Option value={i._id}>{i.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="publish"
                                colon={false}
                                labelAlign="left"
                                label="Publish on listing"
                                rules={[{ required: true, message: 'Publish is not selected!' }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}