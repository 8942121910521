import React, { useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
// import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Col, Row, Statistic } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboardReports } from '../../../store/reports/action'

const Dashboard = () => {

  const dispatch = useDispatch()

  const dashboard = useSelector(state => state.reports.dashboard)

  useEffect(() => {
    dispatch(fetchDashboardReports())
  }, [])

  return (
    <div className='shadow bg-white p-4'>
      <PageHeader
        ghost={false}
        title="Dashboard"
      ></PageHeader>

      <div>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Total Trees Impact Rewards redeemed"
                    value={dashboard?.totalImpactRewardsRedeemed?.tree || 0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Total Trash Impact Rewards redeemed"
                    value={dashboard?.totalImpactRewardsRedeemed?.trash || 0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Total Meals Impact Rewards redeemed"
                    value={dashboard?.totalImpactRewardsRedeemed?.meal || 0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Total transactions (Completed)"
                value={dashboard?.totalCompletedTransaction || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="No of pending transactions to be approved"
                value={dashboard?.totalPendingTransactionToBeApproved || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Users"
                value={dashboard?.users || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card></Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Goals / Habits"
                value={`${dashboard?.goals} / ${dashboard?.habits}`}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Activities"
                value={dashboard?.activities || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Challenges"
                value={dashboard?.challenges || 0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>

        </Row>
      </div>
    </div>
  )
}

export default Dashboard