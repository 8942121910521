import { Button, Input, Form, message, Select, Flex } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SUSGAIN_ROLES } from '../../../const/roles'
import { createUser } from '../../../store/users/action'


export const AddEditAdminUsers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

    const [form] = Form.useForm()
    const [roles, setRoles] = useState(Object.values(SUSGAIN_ROLES))
    // const [confirmLoading, setConfirmLoading] = useState(false)

    // const usersData = useSelector(state => state.users.users)

    // const onPostSucces = () => {
    //     setConfirmLoading(true)
    //     setTimeout(() => {
    //         form.resetFields()
    //         setVisible(false)
    //         setConfirmLoading(false)
    //     }, 1000)
    // }

    const successCallback = () => {
        navigate('/users/admin')
    }

    const createNewAdminUser = (values) => {
        const data = {
            name: values.name,
            email: values.email,
            password: values.password,
            role: values.role
        }

        dispatch(createUser(data, successCallback))
    }

    return (
        <div className='bg-white p-5'>
            <Form
                name='NewAdminUser'
                form={form}
                labelCol={{
                    span: 4
                }}
                onFinish={(values) => createNewAdminUser(values)}
                initialValues={{ publish: false }}
                autoComplete='new-password'
            >
                <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    colon={false}
                    rules={[{ required: true, message: 'Please enter full name' }]}
                    hasFeedback
                    autoComplete='new-password'
                >
                    <Input autoComplete='new-password' maxLength={256} />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    colon={false}
                    rules={[{ required: true, message: 'Please enter email' }]}
                    extra={'This is login credential to dashboard'}
                    hasFeedback
                    autoComplete='new-password'
                >
                    <Input autoComplete='new-password' maxLength={256} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    labelAlign="left"
                    colon={false}
                    rules={[{ required: true, message: 'Please enter password' }]}
                    hasFeedback
                    extra='Please provide a strong password'
                    autoComplete='new-password'
                >
                    <Input.Password type='password' autoComplete='new-password' maxLength={256} visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }} />
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={['password']}
                    labelAlign="left"
                    colon={false}
                    autoComplete='new-password'
                    rules={[
                        { required: true, message: 'Please confirm password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match!'))
                            }
                        })
                    ]}
                    hasFeedback
                    extra='Confirm Password'
                >
                    <Input.Password type='password' autoComplete='new-password' maxLength={256} visibilityToggle={{ visible: confirmPasswordVisible, onVisibleChange: setConfirmPasswordVisible }}/>
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="role"
                    labelAlign="left"
                    colon={false}
                    rules={[{ required: true, message: 'Please select role' }]}
                    hasFeedback
                    extra="(Community leader can only see their community detail page + create/view activities challenges for that community + use broadcast tool for their community)"
                >
                    <Select
                        allowClear
                        placeholder='Select'
                        optionFilterProp='label'
                    >
                        {roles.map((item, idx) => {
                            return (<Select.Option value={item.role} key={idx}>{item.label}</Select.Option>)
                        })}
                    </Select>
                </Form.Item>

                <Flex justify='center' align='center'>
                    <Button className='mr-5 border-[#AFCEC9] text-[#207868]' size='large' onClick={() => navigate('/users/admin')}>Go Back</Button>
                    <Button className='pl-5 bg-[#207868]' type='primary' size='large' htmlType='submit'>Save Changes</Button>
                </Flex>
            </Form>
        </div>
    )
}